import React from 'react';
import ReactDOM from 'react-dom';
import cookies from 'react-cookies';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase from "./Utils/Firebase";

// Sentry.init({dsn: "https://3d1044706d4f487cae1f63e2daa364c9@sentry.io/1504598"});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
