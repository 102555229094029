import React, {useContext, useEffect, useState} from 'react';

import 'firebase/database';
import TopBg from "../img/bg-top.png";


import GlobalStateContext from "../store/GlobalStateContext";
import Firebase from "../Utils/Firebase";
import DateFormater from "../Utils/DateFormater";

function useVariables(eventId, stageId) {
    const [variables, setVariables] = useState({});

    useEffect(() => {
        return Firebase.listenLiveContent(eventId, stageId, (data, visibility) => {
            let _variables = {...variables};

            Object.keys(_variables).forEach((key) => {
                _variables[key].value = null;
            });

            Object.keys(data).forEach((key) => {
                if (undefined === _variables[key]) {
                    _variables[key] = {};
                }

                _variables[key].value = data[key];
            });

            Object.keys(visibility).forEach((key) => {
                if (undefined === _variables[key]) {
                    _variables[key] = {};
                }

                _variables[key].visibility = visibility[key];
            });

            setVariables({...variables, ..._variables});
        });
    }, [stageId]);

    return variables;
}


const LiveContent = (props) => {
    const {globalState} = useContext(GlobalStateContext);
    const { data } = globalState;
    const currentEventId = Object.keys(data.currentEvents)[0];
    const currentStageId = Object.keys(data.currentEvents[currentEventId].stages)[0];
    const [screen, setScreen] = useState({
        default : true,
        multiple : false,
        disciplinaRound : false,
    });

    const variables = useVariables(currentEventId, currentStageId);

    useEffect(() => {
        if (undefined !== variables['startovky^Text'] && "string" === typeof variables['startovky^Text'].value) {
            setScreen({...screen,
                default : false,
                multiple : true,
            });
        } else {
            setScreen({...screen,
                default : true,
                multiple : false,
            });
        }
    }, [variables]);

    const canRenderVariable = (_name) => {
        const variable = variables[_name.replace('.', '^')];

        if (undefined !== variable) {
            if (false !== variable.visibility) {
                if (undefined !== variable.value && null !== variable.value) {
                    if (typeof variable.value === 'string' && variable.value.trim().length > 0) {
                        return true;
                    }

                    return typeof variable.value !== 'string';
                }
            }
        }

        return false;
    };

    const renderDefaultScreen = () => {
        return <div className="variable-container" id="screen_default">
            { true === canRenderVariable("startovka.Text") ? <div key="startovka.Text" className="text-center"><div className="variable" id="startovka.Text">{variables['startovka^Text'].value || ""}</div></div> : null }
            { true === canRenderVariable("choreografie.Text") ? <div key="choreografie.Text" className="variable" id="choreografie.Text" >{variables['choreografie^Text'].value || ""}</div> : null }
            { true === canRenderVariable("skola.Text") ? <div key="skola.Text" className="variable" id="skola.Text">{variables['skola^Text'].value || ""}</div> : null }
            { true === canRenderVariable("disciplina.Text") ? <div key="disciplina.Text" className="variable" id="disciplina.Text">{variables['disciplina^Text'].value || ""}</div> : null }
            { true === canRenderVariable("round.Text") ? <div key="round.Text" className="variable" id="round.Text">{variables['round^Text'].value || ""}</div> : null }
        </div>
    };

    const renderMultipleScreen = () => {
        return <div className="variable-container" id="screen_default">
            { true === canRenderVariable("startovky.Text") ? <div key="startovky.Text" className="text-center"><div className="variable" id="startovky.Text">{variables['startovky^Text'].value || ""}</div></div> : null }
            { true === canRenderVariable("disciplina.Text") ? <div key="disciplina.Text_2" className="variable" id="disciplina.Text_2">{variables['disciplina^Text'].value || ""}</div> : null }
        </div>
    };

    const renderDisciplinaRoundScreen = () => {
        return <div className="variable-container" id="screen_default">
            { true === canRenderVariable("disciplina.Text") ? <div key="disciplina.Text" className="variable" id="disciplina.Text">{variables['disciplina^Text'].value || ""}</div> : null }
            { true === canRenderVariable("round.Text") ? <div key="round.Text" className="variable" id="round.Text">{variables['round^Text'].value || ""}</div> : null }
        </div>
    };

    const renderScreen = () => {
        if ( true === screen.multiple ) {
            return renderMultipleScreen();
        } else if ( true === screen.disciplinaRound ) {
            return renderDisciplinaRoundScreen();
        }

        return renderDefaultScreen();
    };

    const currentEvent = data.currentEvents[currentEventId];
    const currentStage = null !== currentStageId ? currentEvent.stages[currentStageId] : {};

    return <div className="page--livecontent">
        <div className="top-container">
            <img src={TopBg} alt="Right 2 Dance" id="top_bg" />
        </div>

        <div className="body-container">
            {currentStage ? [
                <h1 key="4f89sdfsd">
                    {currentEvent.title}
                    <span id="date">{DateFormater.format(currentStage.date)}</span>
                </h1>,
                Object.keys(currentEvent.stages).length > 1 ? <h2 key="f89sd7f9s" className="livecontent-stage">{currentStage.title}</h2> : null
            ] : null}


            <div className="container--livecontent">
                { renderScreen() }
            </div>
        </div>
    </div>;
};

export default LiveContent;