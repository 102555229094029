import React, { useState, useEffect } from 'react';
import {Route, MemoryRouter} from 'react-router-dom';
import './css/App.css';
import './css/RD-App.css';
import RDLogo from './img/rd-logo.png';
import CRPLogo from './img/crp-logo.jpg';

import LiveContent from "./Pages/LiveContent";

import useGlobalState from "./store/useGlobalState";
import GlobalStateContext from "./store/GlobalStateContext";

import Api from "./Utils/Api";
import Firebase from "./Utils/Firebase";

const App = () => {
    const {globalState, setGlobalState} = useGlobalState({
        data : {},

        currentEventId : null,
    });
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // ... componentDidMount
        Api.fetchAppData().then((data) => {
            setGlobalState({
                data : data,
            });

            onAppDataReady();
        });
    }, []);

    const onAppDataReady = () => {
        setLoaded(true);

        if ("function" === typeof window.ready) {
            window.ready();
        }
    };

    if (loaded) {
        if (Object.keys(globalState.data.currentEvents).length > 0) {
            return <GlobalStateContext.Provider value={{globalState, setGlobalState}}>
                <div className="content">
                    <LiveContent/>
                    <div className="gradient"/>
                    <div className="crp-footer">
                        <a href="http://cz.crproduction.cz" target="_blank"><img src={CRPLogo} alt="CR Production"/></a>
                    </div>
                </div>
            </GlobalStateContext.Provider>
        } else {
            return <div className="Loader">
                <div className="loader-content">
                    <img src={RDLogo} alt="Right Dance"/>
                    <span>Zrovna neprobíhá žádná akce.</span>
                </div>
                <div className="gradient" />
            </div>
        }
    } else {
        return <div className="Loader">
            <div className="loader-content">
                <img src={RDLogo} alt="Right Dance"/>
            </div>
            <div className="gradient" />
        </div>
    }
};

export default App;
